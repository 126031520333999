import pkceChallenge from 'pkce-challenge'
import { useCallback, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { useSettings } from 'contexts/settings'
import { obtainOauth2Token } from 'services/auth'
import analytics, { AnalyticsEvent } from 'utils/analytics'
import { SHIPPER_GUIDE_URL, BIP_URL, BIP_CLIENT_ID } from 'utils/constants'
import * as storage from 'utils/localStorage'
import userWrapper, { TOKEN_KEY } from 'utils/user'
import { navigateTo } from 'utils/window'

const CLIENT_ID = BIP_CLIENT_ID
const LOGIN_URL = `${BIP_URL}/auth/authorize/`
const LOGIN_REDIRECT_URL = `${SHIPPER_GUIDE_URL}/oauth2-login-callback`
const LOGOUT_URL = `${BIP_URL}/auth/logout/`
const LOGOUT_REDIRECT_URI = `${SHIPPER_GUIDE_URL}/login`

const CODE_VERIFIER_STORAGE_KEY = 'code_verifier'

interface Oauth2FlowProps {
  onLoginSuccess?: () => void
  onLoginFailure?: (error: unknown) => void
}

const useOauth2 = (props?: Oauth2FlowProps) => {
  const location = useLocation()

  // This ref is used to make sure the 'fetchTokens' call is only made once.
  const didFetchTokens = useRef(false)

  const isLoggedIn = useCallback(async () => {
    const accessToken = storage.get(TOKEN_KEY)
    return Promise.resolve(Boolean(accessToken))
  }, [])

  const logIn = useCallback(async () => {
    const { code_verifier, code_challenge } = await pkceChallenge()
    storage.set(CODE_VERIFIER_STORAGE_KEY, code_verifier)

    const params = new URLSearchParams({
      code_challenge,
      response_type: 'code',
      client_id: CLIENT_ID,
      redirect_uri: LOGIN_REDIRECT_URL,
      code_challenge_method: 'S256',
    })

    navigateTo(`${LOGIN_URL}?${params.toString()}`)
  }, [])

  const handleLogInCallback = useCallback(async () => {
    const params = new URLSearchParams(location.search)
    const authCode = params.get('code')
    const codeVerifier = storage.get(CODE_VERIFIER_STORAGE_KEY)

    if (!(authCode && codeVerifier)) {
      props?.onLoginFailure?.(new Error('Missing auth code or code verifier'))
      return
    }

    if (!didFetchTokens.current) {
      didFetchTokens.current = true

      try {
        await obtainOauth2Token(authCode, codeVerifier, LOGIN_REDIRECT_URL)
        props?.onLoginSuccess?.()
      } catch (error: unknown) {
        props?.onLoginFailure?.(error)
      }
    }
  }, [props, location.search])

  const logOut = useCallback(() => {
    userWrapper.cleanUserStorage()
    analytics.track(AnalyticsEvent.NavbarLogout)
    analytics.reset()

    const params = new URLSearchParams({
      client_id: CLIENT_ID,
      post_logout_redirect_uri: LOGOUT_REDIRECT_URI,
    })
    navigateTo(`${LOGOUT_URL}?${params.toString()}`)
  }, [])

  const {
    isLoading,
    values: [isEnabled],
  } = useSettings(['flags.ENABLE_LOGIN_THROUGH_BIP'])

  return {
    isLoggedIn,
    logIn,
    logOut,
    handleLogInCallback,
    oAuth2Flow: {
      isLoading,
      isEnabled,
    },
  }
}

export default useOauth2
