import * as Yup from 'yup'

import {
  INVALID_EMAIL_MESSAGE,
  INVALID_PHONE_EXTENSION_MESSAGE,
  INVALID_PHONE_NUMBER_MESSAGE,
  REQUIRED_FIELD_MESSAGE,
} from 'constants/errors'
import { transformEmptyStringToNull } from 'utils/yup'

const locationSchema = {
  name: Yup.string()
    .required(REQUIRED_FIELD_MESSAGE)
    .when('address', {
      is: (value: string) => value.trim().length > 0,
      then: Yup.string().notOneOf(
        [Yup.ref('address')],
        'Facility name cannot be its address. Please enter a valid name.'
      ),
    }),
  address: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  address_details: Yup.string().nullable(),
  city: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  state: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  zipcode: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  country: Yup.string().required(REQUIRED_FIELD_MESSAGE),
}

const DIGITS_REGEX = /\d/g

// Considering the optional "+" sign to avoid conflicts with old data
const SIMPLE_PHONE_REGEX = /^(\+)?[1-9]\d*/g

export const contactSchema = Yup.object().shape(
  {
    name: Yup.string().when(['phone', 'country', 'email', 'extension'], {
      is: (
        phone?: string,
        country?: string,
        email?: string,
        extension?: string
      ) => {
        return (
          [phone ?? '', country ?? '', email ?? '', extension ?? '']
            .map((value) => value.trim())
            .filter(Boolean).length === 0
        )
      },
      then: Yup.string().nullable(),
      otherwise: Yup.string().required(REQUIRED_FIELD_MESSAGE),
    }),
    country: Yup.string().nullable(),
    phone: Yup.string().when(['name', 'country', 'email', 'extension'], {
      is: (
        name?: string,
        country?: string,
        email?: string,
        extension?: string
      ) => {
        return (
          [name ?? '', country ?? '', email ?? '', extension ?? '']
            .map((value) => value.trim())
            .filter(Boolean).length === 0
        )
      },
      then: Yup.string().nullable().matches(SIMPLE_PHONE_REGEX, {
        message: INVALID_PHONE_NUMBER_MESSAGE,
        excludeEmptyString: true,
      }),
      otherwise: Yup.string()
        .required(REQUIRED_FIELD_MESSAGE)
        .matches(SIMPLE_PHONE_REGEX, {
          message: INVALID_PHONE_NUMBER_MESSAGE,
          excludeEmptyString: true,
        }),
    }),
    extension: Yup.string()
      .nullable()
      .transform(transformEmptyStringToNull)
      .matches(DIGITS_REGEX, {
        message: INVALID_PHONE_EXTENSION_MESSAGE,
        excludeEmptyString: true,
      }),
    email: Yup.string()
      .nullable()
      .transform(transformEmptyStringToNull)
      .email(INVALID_EMAIL_MESSAGE),
  },
  [['name', 'phone']]
)

const operatingHoursSchema = {
  operating_hours: Yup.array()

    .when('enabled', {
      is: true,
      then: Yup.array().of(
        Yup.object().shape({
          opens: Yup.string().nullable().required(REQUIRED_FIELD_MESSAGE),
          closes: Yup.string().nullable().required(REQUIRED_FIELD_MESSAGE),
          appointment_mode: Yup.string()
            .nullable()
            .required(REQUIRED_FIELD_MESSAGE),
        })
      ),
      otherwise: Yup.array().nullable(),
    }),
  enabled: Yup.boolean().nullable(),
}

export const facilitySchema = Yup.object().shape({
  location: Yup.object().shape(locationSchema),
  contacts: Yup.array().of(contactSchema).required(),
  hours_of_operation: Yup.object().shape({
    Monday: Yup.object().shape(operatingHoursSchema),
    Tuesday: Yup.object().shape(operatingHoursSchema),
    Wednesday: Yup.object().shape(operatingHoursSchema),
    Thursday: Yup.object().shape(operatingHoursSchema),
    Friday: Yup.object().shape(operatingHoursSchema),
    Saturday: Yup.object().shape(operatingHoursSchema),
    Sunday: Yup.object().shape(operatingHoursSchema),
  }),
})
