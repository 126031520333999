import { Banner, Drawer, Icon, Layout, Text } from '@loadsmart/miranda-react'
import { get, set } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { FacilityContactsFormCard as ContactsSection } from 'components/FacilityContactsForm/FacilityContactsFormCard'
import { useFacilityDetailsV2 } from 'components/FacilityDetails/useFacilityDetailsV2'
import type { FacilityDetailsV2 } from 'services/facilities'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'
import { scrollToTop } from 'utils/scroll'
import toArray from 'utils/toArray'
import { hasTransientError } from 'utils/transient'

import { AccessorialsSection } from './AccessorialsSection'
import { CardSkeleton } from './CardSkeleton'
import {
  createTransientFacilityFromFacilityDetailsV2,
  createTransientFacilityV2,
  DEFAULT_ERROR_BANNER_MESSAGE,
  UNKNOWN_UPDATE_SERVER_ERROR_BANNER_MESSAGE,
  validateTransientFacility,
} from './Facility.helpers'
import type { TransientFacilityV2 } from './Facility.types'
import { InstructionsSection } from './InstructionsSection'
import { LocationSection } from './LocationSection'
import { OperatingHoursSection } from './OperatingHoursSection'
import { useSaveFacility } from './useSaveFacility'

type ErrorState = {
  readonly errorType: 'validation' | 'server'
  readonly errorMessage: string
  readonly serverErrors?: string[]
}

function FacilityEditErrorBanner({
  errorType,
  errorMessage,
  serverErrors,
  hasTransientErrors,
}: ErrorState & {
  readonly hasTransientErrors: boolean
}) {
  if (errorType === 'server') {
    return (
      <Banner variant="danger">
        <Banner.Title>{errorMessage}</Banner.Title>
        {serverErrors && serverErrors.length > 0 && (
          <Banner.Description>
            <Layout.Stack gap="none">
              {serverErrors.map((error) => (
                <Text key={error} color="color-text-secondary">
                  {error}
                </Text>
              ))}
            </Layout.Stack>
          </Banner.Description>
        )}
      </Banner>
    )
  }

  if (hasTransientErrors) {
    return (
      <Banner variant="danger">
        <Banner.Title>{errorMessage}</Banner.Title>
      </Banner>
    )
  }

  return null
}

type FacilityEditFormBodyContentProps = {
  readonly facilityUUID: string
  readonly facility: TransientFacilityV2
  readonly onSetFacility: (changes: Partial<TransientFacilityV2>) => void
}

function FacilityEditFormBodyContent({
  facilityUUID,
  facility,
  onSetFacility,
}: FacilityEditFormBodyContentProps) {
  const {
    data: retrievedFacility,
    isLoading,
    isError,
    refetch,
  } = useFacilityDetailsV2(facilityUUID)
  const [includeMexOption, setIncludeMexOption] = useState(false)

  useEffect(() => {
    if (retrievedFacility) {
      const initialTransientFacility =
        createTransientFacilityFromFacilityDetailsV2(retrievedFacility)

      onSetFacility(initialTransientFacility)
      // Only if the edited facility is in Mexico, we need to include it as a country option
      setIncludeMexOption(initialTransientFacility.location.country === 'MEX')
    }
    // We just need to run this when the facility is retrieved
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retrievedFacility])

  if (isLoading) {
    return (
      <>
        <CardSkeleton title="Location" ariaLabel="Loading facility" />
        <CardSkeleton title="Contacts" />
        <CardSkeleton title="Operating hours" />
        <CardSkeleton title="Instructions" />
        <CardSkeleton title="Accessorials" />
      </>
    )
  }

  if (isError || !retrievedFacility) {
    return (
      <Banner variant="danger">
        <Banner.Title>Failed to load the facility</Banner.Title>
        <Banner.Actions>
          <Banner.ActionPrimary onClick={() => refetch()}>
            Try again
          </Banner.ActionPrimary>
        </Banner.Actions>
      </Banner>
    )
  }

  return (
    <>
      <LocationSection
        collapsible
        location={facility.location}
        onChange={(newLocation) => {
          onSetFacility({
            location: newLocation,
          })
        }}
        includeMexOption={includeMexOption}
      />
      <ContactsSection
        collapsible
        initialCollapsed
        contacts={facility.contacts}
        onChange={(newContacts) => {
          onSetFacility({
            contacts: newContacts,
          })
        }}
      />
      <OperatingHoursSection
        collapsible
        initialCollapsed
        weeklyOperatingHours={facility.hours_of_operation}
        onChange={(newWeeklyOperatingHours) => {
          onSetFacility({
            hours_of_operation: newWeeklyOperatingHours,
          })
        }}
      />
      <InstructionsSection
        collapsible
        initialCollapsed
        instruction={get(facility, 'notes.0')}
        onChange={(newNote) => {
          onSetFacility({
            notes: [newNote],
          })
        }}
      />
      <AccessorialsSection
        collapsible
        initialCollapsed
        accessorials={facility.accessorials}
        onChange={(newAccessorials) => {
          onSetFacility({ accessorials: newAccessorials })
        }}
      />
    </>
  )
}

type FacilityEditFormProps = {
  readonly facilityUUID: string
  readonly onClose: () => void
  readonly onFacilitySaved?: (facility: FacilityDetailsV2) => void
}

export function FacilityEditForm({
  facilityUUID,
  onClose,
  onFacilitySaved,
}: FacilityEditFormProps) {
  const [facility, setFacility] = useState<TransientFacilityV2>(() =>
    createTransientFacilityV2()
  )
  const [errorState, setErrorState] = useState<ErrorState>()

  const onScrollToTopOfDrawer = useCallback(() => {
    scrollToTop('.facility-edit-form-drawer-body')
  }, [])

  const { saveFacility, isSaving } = useSaveFacility({
    facilityUUID,
    onSuccess: (updatedFacility) => {
      onFacilitySaved?.(updatedFacility)
      analytics.track(
        AnalyticsEvent.FacilityManagementFacilityUpdated,
        AnalyticsEventTrigger.click,
        {
          place: 'drawer',
          has_contacts: toArray(updatedFacility.contacts).length > 0,
          has_instructions: toArray(updatedFacility.notes).length > 0,
          instructions_per_mode: false, // this feature has not been implemented yet
          hours_per_mode: false, // this feature has not been implemented yet
        }
      )
      onClose()
      toast.success('Facility updated')
    },
    onError: (errors) => {
      setErrorState({
        errorType: 'server',
        errorMessage: errors.length
          ? DEFAULT_ERROR_BANNER_MESSAGE
          : UNKNOWN_UPDATE_SERVER_ERROR_BANNER_MESSAGE,
        serverErrors: errors,
      })
      onScrollToTopOfDrawer()
    },
  })

  const onSetFacility = useCallback(
    (changes: Partial<TransientFacilityV2>) => {
      setFacility((currentFacility) => {
        const updatedFacility = Object.keys(changes).reduce(
          (newFacility, path) => {
            return set(newFacility, path, get(changes, path))
          },
          structuredClone(currentFacility)
        )

        // If there were validation errors previously found, run validations when values change
        if (errorState?.errorType === 'validation') {
          const [validatedFacility] = validateTransientFacility(updatedFacility)

          return validatedFacility
        }

        return updatedFacility
      })
    },
    [errorState?.errorType]
  )

  const onSaveClick = useCallback(() => {
    const [validatedFacility, isValid] = validateTransientFacility(facility)

    setFacility(validatedFacility)

    if (isValid) {
      setErrorState(undefined)
      saveFacility(validatedFacility)
    } else {
      setErrorState({
        errorType: 'validation',
        errorMessage: DEFAULT_ERROR_BANNER_MESSAGE,
      })
      onScrollToTopOfDrawer()
    }
  }, [saveFacility, facility, onScrollToTopOfDrawer])

  return (
    <>
      <Drawer.Header role="heading" aria-level={1}>
        Edit facility
        <Drawer.Close aria-label="Close facility drawer" />
      </Drawer.Header>

      <Drawer.Body className="facility-edit-form-drawer-body">
        <Layout.Stack gap="spacing-6">
          {errorState && (
            <FacilityEditErrorBanner
              {...errorState}
              hasTransientErrors={hasTransientError(facility)}
            />
          )}

          <FacilityEditFormBodyContent
            facilityUUID={facilityUUID}
            facility={facility}
            onSetFacility={onSetFacility}
          />
        </Layout.Stack>
      </Drawer.Body>

      <Drawer.Actions justify="flex-start">
        <Drawer.ActionTertiary
          leading={<Icon name="arrow-sort-up" />}
          onClick={onScrollToTopOfDrawer}
        >
          Back to top
        </Drawer.ActionTertiary>
        <Drawer.ActionTertiary
          disabled={isSaving}
          onClick={onClose}
          style={{ marginLeft: 'auto' }}
        >
          Cancel
        </Drawer.ActionTertiary>
        <Drawer.ActionPrimary
          disabled={isSaving}
          loading={isSaving}
          onClick={onSaveClick}
        >
          Save facility
        </Drawer.ActionPrimary>
      </Drawer.Actions>
    </>
  )
}
