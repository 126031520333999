import analytics, { AnalyticsEvent } from 'utils/analytics'
import * as storage from 'utils/localStorage'

import {
  ACCOUNT_TYPES,
  ACCOUNT_BUSINESS_TYPE,
  FBM_SHIPPER_LOGIN,
  SHIPPER_LOGIN,
  USER_CATEGORIES,
  USER_TYPE,
  USER_ROLE,
  USER_BUSINESS,
} from './constants'
import {
  getShipperUUIDFromLocalStorage,
  removeShipperUUIDFromLocalStorage,
  setShipperUUIDToLocalStorage,
} from './shipperLocationManagement'
import { capitalize } from './strings'

export const TOKEN_KEY = 'token'
const TOKEN_REFRESH_KEY = 'token-refresh'
export const USER_KEY = 'user'
/**
 * @deprecated
 * We are keeping this value in order to remove this information from the users' localStorage.
 * It should not be used anywhere else.
 */
const PARSED_ACCESS_KEY = 'parsed-access'

export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch {
    return {}
  }
}

export const isFBMUser = (user?: UserData | null) => {
  return user?.shipper?.business === ACCOUNT_BUSINESS_TYPE.FBM
}

export const isShipperBusinessMTC = (user?: UserData | null) => {
  return user?.shipper?.business === ACCOUNT_BUSINESS_TYPE.MTC
}

export const isAdminUser = (user?: UserData | null) => {
  return user?.user_role === USER_ROLE.ADMIN
}

export const isStandardUser = (user?: Pick<UserData, 'user_role'> | null) => {
  return user?.user_role === USER_ROLE.STANDARD
}

export const isSupervisorUser = (user?: UserData | null) => {
  return user?.user_role === USER_ROLE.SUPERVISOR
}

export const isMTCustomerUser = (user?: UserData | null) => {
  return isShipperBusinessMTC(user) && user?.user_business === USER_BUSINESS.MTC
}

export const isMTInternalUser = (user?: UserData | null) => {
  return isShipperBusinessMTC(user) && user?.user_business === USER_BUSINESS.STD
}

export const isPaidUser = (user?: UserData | null) => {
  return user?.user_category === USER_CATEGORIES.PAID_SHIPPER
}

const getUserType = (user?: UserData | null) => {
  if (isFBMUser(user)) {
    return USER_TYPE.FBM
  }

  if (isMTCustomerUser(user)) {
    return USER_TYPE.MTC
  }

  return USER_TYPE.SG
}

type FormattedUserData = {
  id: string
  email: string
  name: string
  company: string | undefined
  type: string
  shipper_test: boolean
  user_category: UserCategory
  business_type: ShipperBusiness | null
}

export const formatUserForAnalytics = (user: UserData): FormattedUserData => {
  return {
    id: user.user_uuid,
    email: user.user_email,
    name: user.user_name,
    company: user.shipper_company,
    type: user.account_type
      ? capitalize(user.account_type)
      : ACCOUNT_TYPES?.SHIPPER,
    shipper_test: user.user_is_test,
    user_category: user.user_category,
    business_type: user.shipper?.business,
  }
}

export const formatUserData = ({
  userData,
  accessToken,
  refreshToken,
  exp,
}: {
  userData: RawUserData
  accessToken: UserData['accessToken']
  refreshToken: UserData['refreshToken']
  exp: UserData['exp']
}): UserData => {
  return {
    accessToken,
    refreshToken,
    exp,
    account_type: userData.account_type,
    shipper: {
      business: userData.shipper?.business,
      external_uuid: userData.shipper.external_uuid || '',
      latest_tender_acceptance: userData.shipper.latest_tender_acceptance,
    },
    shipper_company: userData.shipper.company_name,
    has_supervisor_user_in_shipper_network:
      userData.shipper.has_supervisor_user_in_shipper_network,
    shipper_is_active: userData.shipper.is_active,
    is_managed_trans: userData.shipper.is_managed_trans,
    shipper_logo: userData.shipper.logo,
    shipper_location_default: userData.shipper.uuid,
    user_category: userData.user_category,
    user_date_joined: userData.user_date_joined,
    user_email: userData.user_email,
    user_is_test: userData.user_is_test,
    user_business: userData.user_business,
    user_name: userData.user_name,
    user_role: userData.user_role,
    user_uuid: userData.user_uuid,
    is_supervisor: userData.user_role === USER_ROLE.SUPERVISOR,
    business: userData.shipper?.business,
  }
}

export class User {
  setTokens = (tokens: { access: string; refresh: string }) => {
    storage.set(TOKEN_KEY, tokens.access)
    storage.set(TOKEN_REFRESH_KEY, tokens.refresh)
  }

  setUser = (user: UserData) => {
    storage.set(USER_KEY, user)
  }

  setSessionShipperUUID = (user: UserData) => {
    const hasSessionUUID = this.locationUUID

    if (!hasSessionUUID && user.is_supervisor) {
      setShipperUUIDToLocalStorage(user.shipper_location_default)
    }
  }

  get accessToken() {
    return storage.get(TOKEN_KEY)
  }

  get refreshToken() {
    return storage.get(TOKEN_REFRESH_KEY)
  }

  get parsedAccess(): UserAccess {
    return parseJwt(this.accessToken)
  }

  get user(): UserData | null {
    return storage.get(USER_KEY)
  }

  get locationUUID(): string | null {
    return getShipperUUIDFromLocalStorage()
  }

  get accountType(): AccountType | null {
    return this.user?.account_type as AccountType
  }

  get isFBMUser(): boolean {
    return isFBMUser(this.user)
  }

  get isAdminUser(): boolean {
    return isAdminUser(this.user)
  }

  get isStandardUser(): boolean {
    return isStandardUser(this.user)
  }

  get isSupervisorUser(): boolean {
    return isSupervisorUser(this.user)
  }

  get isMTCustomerUser(): boolean {
    return isMTCustomerUser(this.user)
  }

  get isMTInternalUser(): boolean {
    return isMTInternalUser(this.user)
  }

  get userType(): USER_TYPE {
    return getUserType(this.user)
  }

  isUserType = (userTypes: USER_TYPE[]) => {
    return userTypes.includes(this.userType)
  }

  // we should refetch the query to update the user data
  updatePartialUser = (newUser: Partial<UserData>) => {
    storage.set(USER_KEY, {
      ...this.user,
      ...newUser,
    })
  }

  get data() {
    return {
      accessToken: this.accessToken || '',
      refreshToken: this.refreshToken || '',
      exp: this.parsedAccess?.exp,
      ...this.user,
    } as UserData
  }

  cleanUserStorage = () => {
    const settingsKeys = ['settings', `settings:${this.user?.user_uuid}`]
    const storageKeys = [
      TOKEN_KEY,
      TOKEN_REFRESH_KEY,
      USER_KEY,
      PARSED_ACCESS_KEY,
      ...settingsKeys,
    ]
    storageKeys.forEach((key) => {
      storage.remove(key)
    })

    removeShipperUUIDFromLocalStorage()
  }

  logout = () => {
    this.cleanUserStorage()
    analytics.track(AnalyticsEvent.NavbarLogout)
    analytics.reset()

    // Getting the login URL before removing the user data from the storage
    const loginURL = this.isFBMUser ? FBM_SHIPPER_LOGIN : SHIPPER_LOGIN
    window.location.replace(loginURL)
  }

  /**
   * This method returns true if the user went through the login process and
   * the access and refresh tokens are present in the current session; however,
   * this method doesn't ensure the tokens aren't expired.
   *
   * The FE will only know if the tokens are valid if any http-request fails with 401,
   * and the tokens cannot be refreshed.
   */
  get isLoggedIn() {
    return this.accessToken != null && this.refreshToken != null
  }
}

export default new User()
