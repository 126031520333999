import { useSettings } from 'contexts/settings'

export function useFacilityDetailsV2Flag() {
  const {
    values: [facilitiesSummaryV2Enabled],
    isLoading: isFacilityDetailsV2Loading,
  } = useSettings(['flags.ENABLE_FACILITY_SUMMARY_V2'])
  const isFacilityDetailsV2Enabled = Boolean(facilitiesSummaryV2Enabled)

  return {
    isFacilityDetailsV2Loading,
    isFacilityDetailsV2Enabled,
  }
}
