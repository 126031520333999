import { Layout, SpinnerWheel, Text } from '@loadsmart/miranda-react'
import { isAxiosError } from 'axios'
import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import useOauth2 from '_shared_/hooks/useOauth2'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'
import { STATUS } from 'utils/httpClient'
import * as storage from 'utils/localStorage'
import queryClient from 'utils/queryClient'

interface LoginThroughBIPCallbackProps {
  readonly getHomePage: () => string
}

const LoginThroughBIPCallback = ({
  getHomePage,
}: LoginThroughBIPCallbackProps) => {
  const history = useHistory()

  const onLoginSuccess = useCallback(() => {
    analytics.track(AnalyticsEvent.LoginSuccess, AnalyticsEventTrigger.success)
    queryClient.refetchQueries({
      queryKey: ['retrieveSettings'],
      exact: false,
    })
    storage.remove('settings')
    history.push(getHomePage())
  }, [history, getHomePage])

  const onLoginFailure = useCallback(
    (error: unknown) => {
      analytics.track(AnalyticsEvent.LoginError, AnalyticsEventTrigger.error)
      if (
        isAxiosError(error) &&
        error.response?.status === STATUS.HTTP_401_UNAUTHORIZED &&
        error.response.data.reset_password_token
      ) {
        history.push(
          `/reset_password/${error.response.data.reset_password_token}?expired=1`
        )
        return
      }
      history.push('/')
    },
    [history]
  )

  const { handleLogInCallback } = useOauth2({
    onLoginSuccess,
    onLoginFailure,
  })

  useEffect(() => {
    handleLogInCallback()
  }, [handleLogInCallback])

  return (
    <Layout.Stack
      justify="center"
      align="center"
      style={{ margin: 'auto', height: '100vh' }}
    >
      <Text variant="heading-sm-bold">Authentication in progress...</Text>
      <SpinnerWheel size="48" />
    </Layout.Stack>
  )
}

export default LoginThroughBIPCallback
